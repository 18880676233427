import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Grid,
  Dialog,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import TabButton from "./components/TabButton.web";
import FilterMenu from "./components/FilterMenu.web";
import ChatbotCard from "./components/ChatbotCard.web";
import ChatbotTable from "./components/ChatbotTable.web";
import CreateBot  from "./components/CreateBot.web";
import FlowControl from "../../../components/src/FlowControl.web";
import {Pagination} from "@material-ui/lab";
import BotHeader from "./components/BotHeader.web";
import ReactFlow, { Background, BackgroundVariant, MarkerType, ReactFlowProvider, Edge } from 'reactflow';
import HomeNode from "../../../components/src/HomeNode.web"
import AddNodeButton from "../../../components/src/AddNodeButton.web";
import SmoothDashEdge from "../../../components/src/SmoothDashEdge.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import { ClearOutlined } from "@material-ui/icons";
import ChatbotErrorDialog from "./components/ChatbotErrorDialog.web";

const CatalogueContainer = styled(Box)({
  display: "flex",
  backgroundColor: "#FAFAFA",
})

const BotsContainer = styled(Box)({
  margin: "20px 48px",
  display: "flex",
  flexDirection: "column",
  gap: 24,
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
  padding: "24px 20px 0",
  boxShadow: "0px 2px 8px 0px #00000014",
  
  "& .MuiPaginationItem-textPrimary.Mui-selected": {
    color: "#51ABB3",
    backgroundColor: "transparent"
  },
  "& .MuiPaginationItem-page:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  },
})

const ActionsContainer = styled(Box)({
  display: "flex",
  gap: 10,
  alignItems: "center",
  flexWrap: "wrap",
  padding: 10,
})

const ChatbotGrid = styled(Grid)({
  overflowY: "auto",
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': {
    width: 0,
  },
})

const DeleteBotHeading = styled("h2")({
  margin: 0,
  fontFamily: "Cairo",
  fontWeight: 700,
  fontSize: 24,
  lineHeight: "32px",
  color: "#0F172A",
  textAlign: "center",
})

const DeleteBotSubHeading = styled("p")({
  margin: 0,
  fontWeight: 400,
  fontFamily: "Cairo",
  fontSize: 16,
  textAlign: "center",
  lineHeight: "24px",
  color: "#000000",
  marginTop: 28,
  marginBottom: 28,
})

const DeleteBotButton = styled("button")({
  all: "unset",
  cursor: "pointer",
  flex: 1,
  padding: 10,
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 700,
  fontFamily: "Cairo",
  fontSize: 16,
  lineHeight: "24px",
  backgroundColor: "#51ABB3",
  color: "#FFFFFF",
  '&.secondary': {
    backgroundColor: "#DBF0F2",
    color: "#51ABB3"
  },
})

const CloseDeleteBotModalButton = styled("button")({
  all: "unset",
  cursor: "pointer",
  position: "absolute",
  top: 30,
  right: 30,
  boxSizing: "border-box",
  padding: 4,
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-primary': {
          backgroundColor: "#51ABB3",
          borderRadius: 8,
          padding: "6px 10px",
          marginLeft: "auto",
          textTransform: "none",
        },
        '&.Mui-primary $label': {
          color: "#FFFFFF",
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 16,
          lineHeight: "24px",
        },
      },
    },
    MuiBackdrop: {
      root: {
          backgroundColor: '#0F172A',
      },
    },
    MuiPaper: {
      root: {
        boxSizing: "border-box",
        padding: 32,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        '&.no-default': {
          backgroundColor: "transparent",
          padding: 0,
          width: "fit-content",
          margin: 0,
          overflow: "hidden",
        },
      },
      elevation24: {
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        '&.shadow-none': {
          boxShadow: "none",
        },
      },
      rounded: {
        borderRadius: 8,
      }
    },
    MuiDialog: {
      paperWidthSm: {
        width: 430,
        maxWidth: 430,
      },
    },
  },
});
// Customizable Area End

import Catalogue1Controller, {
  Props,
  configJSON,
} from "./Catalogue1Controller";

export default class Catalogue1 extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  initialNodes = [
    { id: 'n-1', type: "homeNode", position: { x: 24, y: 24 }, data: { label: 'Home' } },
    { id: 'n-2', type: "addNode", position: { x: 157, y: 138 }, data: { label: 'Add Node' } },
  ];
  initialEdges: Edge[] = [
    { 
      id: 'e1-2', 
      source: 'n-1', 
      target: 'n-2',
      type: "smoothDashEdge",
      markerEnd: { type: MarkerType.ArrowClosed, color: "#51ABB3" },
      data: { strokeStyle: "dashed" } 
    }
  ];

  nodeTypes = {
    homeNode: HomeNode,
    addNode: AddNodeButton
  }

  edgeTypes = {
    smoothDashEdge: SmoothDashEdge
  }
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CreateBot 
          isOpen={this.state.showCreateBotDialog} 
          onClose={this.onToggleCreateBotDialog}
          {...this.props} 
        />
        <Dialog data-testid="overlay-loader" open={this.state.isLoading} PaperProps={{ className: "no-default shadow-none" }} BackdropProps={{ style: { opacity: 0.4 } }} >
          <CircularProgress style={{width: 60, height: 60, color: "#FFFFFF"}} />
        </Dialog>
        <Dialog 
          data-testid="delete-bot-popup" 
          open={!!this.state.selectedChatbot} 
          onClose={this.handleCloseDeleteBotConfirmModal} 
          BackdropProps={{ style: { opacity: 0.4 } }} 
          PaperProps={{ "aria-labelledby": "dialogTitle", "aria-describedby": "dialogDesc" }}
        > 
          <CloseDeleteBotModalButton onClick={this.handleCloseDeleteBotConfirmModal} >
            <ClearOutlined htmlColor="#0F172A" />
          </CloseDeleteBotModalButton>
          <DeleteBotHeading id="dialogTitle" >Delete Bot</DeleteBotHeading>
          <DeleteBotSubHeading id="dialogDesc" >Are you sure want to delete {this.state.selectedChatbot?.name}?</DeleteBotSubHeading>
          <div style={{display: "flex", gap: 12}} >
            <DeleteBotButton data-testid="cancel-delete-bot" className="secondary" onClick={this.handleCloseDeleteBotConfirmModal} >Cancel</DeleteBotButton>
            <DeleteBotButton data-testid="delete-bot" onClick={this.handleDeleteChatBot} >Delete</DeleteBotButton>
          </div>
        </Dialog>
        <ChatbotErrorDialog data-testid="chatbot-cards-error" open={this.state.hasChatbotCardsError} onClose={this.handleCloseBotConnectorCardsError} >
          <h4>Chatbot has errors!</h4>
          <p>Couldn't set chatbot to active as there are errors in the flow</p>
          <button className="primary-btn" data-testid="edit-chatbot" onClick={this.handleEditChatbot} >Edit chatbot</button>
        </ChatbotErrorDialog>
        <ChatbotErrorDialog data-testid="chatbot-settings-error" open={this.state.hasChatbotSettingsError} onClose={this.handleCloseSettingsError} >
          <h4>Settings are not completed!</h4>
          <p>Couldn't set chatbot to active as the chatbot settings are not completed</p>
          <button data-testid="go-to-chatbot-settings" onClick={this.handleNavigateToBotSettingsPage} >Go to settings</button>
        </ChatbotErrorDialog>
        <CatalogueContainer>
          <Sidebar activeMenu="bots" navigation={this.props.navigation}/>
          {
            this.state.showCreateBotDialog ? (
              <div style={{width: "100%"}} >
                <BotHeader />
                <div style={{ width: '100%', height: 'calc(100vh - 92px)' }} >
                  <ReactFlowProvider>
                    <ReactFlow 
                      nodes={this.initialNodes} 
                      edges={this.initialEdges} 
                      proOptions={{hideAttribution: true}}
                      nodeTypes={this.nodeTypes}
                      edgeTypes={this.edgeTypes} 
                    >
                      <FlowControl />
                      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                    </ReactFlow>
                  </ReactFlowProvider>
                </div>
              </div>
            ) : (
              <Box sx={{width: "100%"}} >
                <CustomAppHeader title="Bots" navigation={this.props.navigation} showSearchBox={true} searchInputVal={this.state.search} onSearchInputChange={this.handleSearchChange} />
                <BotsContainer>
                  <ActionsContainer>
                    <TabButton data-testid="grid-list-tab" value={this.state.tabValue} onValueChange={this.onTabChange} />
                    <FilterMenu />
                    <Button data-testid="addBot" className="Mui-primary" onClick={this.onToggleCreateBotDialog} >Add Bot</Button>
                  </ActionsContainer>
                  {this.state.tabValue === "grid" ? (
                    <ChatbotGrid container spacing={4} role="tabpanel" data-value="grid" id="content-grid" aria-labelledby="trigger-grid" >
                      {
                        this.state.chatbots.map((chatbot) => (
                          <ChatbotCard data-testid={`chatbot ${chatbot.id}`} key={chatbot.id} {...chatbot} navigateToEditPage={this.navigateToEditPage} handleDeleteBot={this.handleOpenDeleteBotConfirmModal} handleDuplicateBot={this.handleDuplicateBotId} />
                        ))
                      }
                    </ChatbotGrid>
                  ) : (
                    <ChatbotTable 
                      data-testid="chatbots-table"
                      chatbots={this.state.chatbots} 
                      navigateToEditPage={this.navigateToEditPage} 
                      handleDeleteBot={this.handleOpenDeleteBotConfirmModal} 
                      handleDuplicateBot={this.handleDuplicateBotId} 
                      handleBotStatusChange={this.handleBotStatusChange}
                    />
                  )}
                 <Pagination
                  count={Math.ceil(this.state.pagination.total / this.state.pagination.itemPerPage) || 1}
                  page={this.state.pagination.currentPage}
                  onChange={this.onPageChange}
                  color="primary"
                  style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', height: "64px" }}
                />
                </BotsContainer>
              </Box>
            )
          }
        </CatalogueContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
};
// Customizable Area End
