import React, { useState } from "react"
import { Button, Modal, Typography, styled, Checkbox } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { theme as styledTheme } from "../theme.web";
import { AssociateFile } from "../types";
const { StyledRow } = styledTheme

type Props = {
    open: boolean;
    onClose: any;
    allAssociatedDataFiles: AssociateFile[];
    handleFileAdd: (fileId: number) => void;
    initialFileDataId: number | undefined;
}

const qaFiles = [
    {
        id: 1,
        type: "filtered_files",
        title: "All files",
        icon: (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 6.74917V17.3292C16.5 19.4192 14.97 21.2792 12.89 21.4792C10.5 21.7092 8.5 19.8392 8.5 17.4992V5.13917C8.5 3.82917 9.44 2.63917 10.74 2.50917C12.24 2.35917 13.5 3.52917 13.5 4.99917V15.4992C13.5 16.0492 13.05 16.4992 12.5 16.4992C11.95 16.4992 11.5 16.0492 11.5 15.4992V6.74917C11.5 6.33917 11.16 5.99917 10.75 5.99917C10.34 5.99917 10 6.33917 10 6.74917V15.3592C10 16.6692 10.94 17.8592 12.24 17.9892C13.74 18.1392 15 16.9692 15 15.4992V5.16917C15 3.07917 13.47 1.21917 11.39 1.01917C9.01 0.789169 7 2.65917 7 4.99917V17.2692C7 20.1392 9.1 22.7092 11.96 22.9792C15.25 23.2792 18 20.7192 18 17.4992V6.74917C18 6.33917 17.66 5.99917 17.25 5.99917C16.84 5.99917 16.5 6.33917 16.5 6.74917Z" fill="#51ABB3" />
            </svg>
        )
    }, 
    {
        id: 2, 
        type: "user guides",
        title: 'User Guides',
        icon: (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 17C17.9167 17 18.2707 16.854 18.562 16.562C18.854 16.2707 19 15.9167 19 15.5C19 15.0833 18.854 14.7293 18.562 14.438C18.2707 14.146 17.9167 14 17.5 14C17.0833 14 16.7293 14.146 16.438 14.438C16.146 14.7293 16 15.0833 16 15.5C16 15.9167 16.146 16.2707 16.438 16.562C16.7293 16.854 17.0833 17 17.5 17ZM17.5 20C18 20 18.4667 19.8833 18.9 19.65C19.3333 19.4167 19.6917 19.0917 19.975 18.675C19.5917 18.4417 19.1917 18.271 18.775 18.163C18.3583 18.0543 17.9333 18 17.5 18C17.0667 18 16.6417 18.0543 16.225 18.163C15.8083 18.271 15.4083 18.4417 15.025 18.675C15.3083 19.0917 15.6667 19.4167 16.1 19.65C16.5333 19.8833 17 20 17.5 20ZM4.5 20C3.95 20 3.479 19.8043 3.087 19.413C2.69567 19.021 2.5 18.55 2.5 18V4C2.5 3.45 2.69567 2.979 3.087 2.587C3.479 2.19567 3.95 2 4.5 2H18.5C19.05 2 19.521 2.19567 19.913 2.587C20.3043 2.979 20.5 3.45 20.5 4V10.7C20.1833 10.55 19.8583 10.4207 19.525 10.312C19.1917 10.204 18.85 10.125 18.5 10.075V4H4.5V18H10.55C10.6 18.3667 10.6793 18.7167 10.788 19.05C10.896 19.3833 11.025 19.7 11.175 20H4.5ZM4.5 18V4V10.075V10V18ZM6.5 16H10.575C10.625 15.65 10.704 15.3083 10.812 14.975C10.9207 14.6417 11.0417 14.3167 11.175 14H6.5V16ZM6.5 12H12.6C13.1333 11.5 13.729 11.0833 14.387 10.75C15.0457 10.4167 15.75 10.1917 16.5 10.075V10H6.5V12ZM6.5 8H16.5V6H6.5V8ZM17.5 22C16.1167 22 14.9377 21.5127 13.963 20.538C12.9877 19.5627 12.5 18.3833 12.5 17C12.5 15.6167 12.9877 14.4373 13.963 13.462C14.9377 12.4873 16.1167 12 17.5 12C18.8833 12 20.0627 12.4873 21.038 13.462C22.0127 14.4373 22.5 15.6167 22.5 17C22.5 18.3833 22.0127 19.5627 21.038 20.538C20.0627 21.5127 18.8833 22 17.5 22Z" fill="#3CC2E0" />
            </svg>
        )
    }, 
    {
        id: 3, 
        type: "forms",
        title: "Forms",
        icon: (
            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2H12.32C11.9 0.84 10.8 0 9.5 0C8.2 0 7.1 0.84 6.68 2H2.5C1.4 2 0.5 2.9 0.5 4V20C0.5 21.1 1.4 22 2.5 22H16.5C17.6 22 18.5 21.1 18.5 20V4C18.5 2.9 17.6 2 16.5 2ZM9.5 2C10.05 2 10.5 2.45 10.5 3C10.5 3.55 10.05 4 9.5 4C8.95 4 8.5 3.55 8.5 3C8.5 2.45 8.95 2 9.5 2ZM2.5 19C2.5 19.55 2.95 20 3.5 20H15.5C16.05 20 16.5 19.55 16.5 19V5C16.5 4.45 16.05 4 15.5 4H14.5V5C14.5 6.1 13.6 7 12.5 7H6.5C5.4 7 4.5 6.1 4.5 5V4H3.5C2.95 4 2.5 4.45 2.5 5V19Z" fill="#2ED67B" />
            </svg>
        )
    }, 
    {
        id: 4, 
        type: "signposting",
        title: "Signposting",
        icon: (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4V0.5C0.5 0.22 0.72 0 1 0H4.5L0.5 4ZM16.5 4L12.5 0H16C16.28 0 16.5 0.22 16.5 0.5V4ZM16.5 15.5V12L12.5 16H16C16.28 16 16.5 15.78 16.5 15.5ZM4.5 16H1C0.72 16 0.5 15.78 0.5 15.5V12L4.5 16ZM15.5 8C15.5 4.13 12.37 1 8.5 1C4.63 1 1.5 4.13 1.5 8C1.5 11.87 4.63 15 8.5 15C12.37 15 15.5 11.87 15.5 8ZM3.5 8C3.5 10.76 5.74 13 8.5 13C11.26 13 13.5 10.76 13.5 8C13.5 5.24 11.26 3 8.5 3C5.74 3 3.5 5.24 3.5 8Z" fill="#F59E0B" />
            </svg>
        )
    }
];

const fileTypeIcon: Record<string, React.ReactNode> = {
    "user guides": (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 17C17.4167 17 17.7707 16.854 18.062 16.562C18.354 16.2707 18.5 15.9167 18.5 15.5C18.5 15.0833 18.354 14.7293 18.062 14.438C17.7707 14.146 17.4167 14 17 14C16.5833 14 16.2293 14.146 15.938 14.438C15.646 14.7293 15.5 15.0833 15.5 15.5C15.5 15.9167 15.646 16.2707 15.938 16.562C16.2293 16.854 16.5833 17 17 17ZM17 20C17.5 20 17.9667 19.8833 18.4 19.65C18.8333 19.4167 19.1917 19.0917 19.475 18.675C19.0917 18.4417 18.6917 18.271 18.275 18.163C17.8583 18.0543 17.4333 18 17 18C16.5667 18 16.1417 18.0543 15.725 18.163C15.3083 18.271 14.9083 18.4417 14.525 18.675C14.8083 19.0917 15.1667 19.4167 15.6 19.65C16.0333 19.8833 16.5 20 17 20ZM4 20C3.45 20 2.979 19.8043 2.587 19.413C2.19567 19.021 2 18.55 2 18V4C2 3.45 2.19567 2.979 2.587 2.587C2.979 2.19567 3.45 2 4 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V10.7C19.6833 10.55 19.3583 10.4207 19.025 10.312C18.6917 10.204 18.35 10.125 18 10.075V4H4V18H10.05C10.1 18.3667 10.1793 18.7167 10.288 19.05C10.396 19.3833 10.525 19.7 10.675 20H4ZM4 18V4V10.075V10V18ZM6 16H10.075C10.125 15.65 10.204 15.3083 10.312 14.975C10.4207 14.6417 10.5417 14.3167 10.675 14H6V16ZM6 12H12.1C12.6333 11.5 13.229 11.0833 13.887 10.75C14.5457 10.4167 15.25 10.1917 16 10.075V10H6V12ZM6 8H16V6H6V8ZM17 22C15.6167 22 14.4377 21.5127 13.463 20.538C12.4877 19.5627 12 18.3833 12 17C12 15.6167 12.4877 14.4373 13.463 13.462C14.4377 12.4873 15.6167 12 17 12C18.3833 12 19.5627 12.4873 20.538 13.462C21.5127 14.4373 22 15.6167 22 17C22 18.3833 21.5127 19.5627 20.538 20.538C19.5627 21.5127 18.3833 22 17 22Z" fill="#3CC2E0" />
        </svg>
    ),
    "forms": (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V21C3 22.1 3.9 23 5 23H19C20.1 23 21 22.1 21 21V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20V6C19 5.45 18.55 5 18 5H17V6C17 7.1 16.1 8 15 8H9C7.9 8 7 7.1 7 6V5H6C5.45 5 5 5.45 5 6V20Z" fill="#2ED67B" />
        </svg>
    ),
    "signposting": (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 8V4.5C4.5 4.22 4.72 4 5 4H8.5L4.5 8ZM20.5 8L16.5 4H20C20.28 4 20.5 4.22 20.5 4.5V8ZM20.5 19.5V16L16.5 20H20C20.28 20 20.5 19.78 20.5 19.5ZM8.5 20H5C4.72 20 4.5 19.78 4.5 19.5V16L8.5 20ZM19.5 12C19.5 8.13 16.37 5 12.5 5C8.63 5 5.5 8.13 5.5 12C5.5 15.87 8.63 19 12.5 19C16.37 19 19.5 15.87 19.5 12ZM7.5 12C7.5 14.76 9.74 17 12.5 17C15.26 17 17.5 14.76 17.5 12C17.5 9.24 15.26 7 12.5 7C9.74 7 7.5 9.24 7.5 12Z" fill="#F59E0B" />
        </svg>
    )
}

const UnCheckedIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#64748B"/>
    </svg>
)

const CheckedIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="4" fill="#51ABB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white"/>
    </svg>
)

export default function AddAssociatesModal({ 
    open, 
    onClose, 
    allAssociatedDataFiles,
    handleFileAdd,
    initialFileDataId, 
}: Props) {
    const [selectedFileType, setSelectedFileType] = useState("filtered_files")
    const [selectedFileDataId, setSelectedFileDataId] = useState(initialFileDataId)

    let allFiles = allAssociatedDataFiles
    if(selectedFileType !== "filtered_files") {
        allFiles = allFiles.filter(file => file.fileType === selectedFileType)
    }

    const onFileAdd = () => {
        const selectedFile = allFiles.find(file => file.data_id = selectedFileDataId!)
        if(selectedFile) {
            handleFileAdd(selectedFile.id)
        }
    }
    
    return (
        <ModalBody
            open={open}
            onClose={onClose}
            className="customDialog"
            data-testid="add-associate-modal"
        >
            <div className="dialog">
                <StyledRow className="space-between dialog-header">
                    <Typography variant="h4" style={{ fontSize: "24px" }}>Add associated resource</Typography>
                    <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
                </StyledRow>
                <StyledRow className="dialog-body flex-column">
                    <StyledRow className="items-center space-between" style={{ width: "100%", gap: "12px" }}>
                        {qaFiles.map((file) => (
                            <Button 
                                key={file.type} 
                                className="custom-btn" 
                                data-active={file.type === selectedFileType} 
                                onClick={() => {
                                    setSelectedFileType(file.type)
                                }}
                                data-testid={file.title}
                            >
                                {file.icon}
                                <Typography>{file.title}</Typography>
                            </Button>
                        ))}
                    </StyledRow>
                    <StyledRow className="flex-column" style={{ gap: "12px" }}>
                        {allFiles.map((file) => (
                            <StyledRow 
                                key={file.id} 
                                data-testid={"associate_"+file.id}
                                data-active={file.data_id === selectedFileDataId}
                                className={`check-box ${file.data_id === selectedFileDataId ? "isChecked" : ""}`} 
                                onClick={() => setSelectedFileDataId(file.data_id)}
                            >
                                <Checkbox inputProps={{"aria-label": "check "+file.title, "aria-checked": file.data_id === selectedFileDataId}} checked={file.data_id === selectedFileDataId} icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />
                                <div className="box">
                                    <div style={{ width: "38px" }}>
                                        {fileTypeIcon[file.fileType]}
                                    </div>
                                    <Typography>{file.title}</Typography>
                                </div>
                            </StyledRow>
                        ))}
                    </StyledRow>
                </StyledRow>
                <StyledRow style={{ justifyContent: "flex-end" }} className="dialog-footer">
                    <Button data-testid="cancel-btn" className="Btn" onClick={onClose}>Cancel</Button>
                    <Button 
                        className="Btn" 
                        style={{ background: "#51ABB3", color: "#fff", }} 
                        onClick={onFileAdd}
                        data-testid="add-associate-btn"
                    >
                        Add
                    </Button>
                </StyledRow>
            </div>
        </ModalBody>
    )
}

const ModalBody = styled(Modal)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    fontFamily: "Cairo !important",

    "& .dialog": {
        background: "#fff",
        borderRadius: "24px",
        width: "648px",
        maxHeight: "calc(100dvh - 32%)",
        margin: "0 auto",
        outline: "none",
        boxSizing: "border-box",
        overflowY: "auto",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    "& .dialog-header": {
        padding: "24px 40px 24px 40px",
        gap: "10px",
        borderBottom: "1px solid #CBD5E1",
        position: "sticky",
        top: 0,
        zIndex: 10,
        backgroundColor: "#FFFFFF",
    },
    "& .dialog-footer": {
        position: "sticky",
        bottom: 0,
        zIndex: 10,
        padding: "24px 40px 24px 40px",
        gap: "10px",
        borderTop: "1px solid #CBD5E1",
        backgroundColor: "#FFFFFF",
    },
    "& .dialog-body": {
        padding: "24px 40px 24px 40px",
        gap: "16px",
    },
    "& .dialog-body .custom-btn": {
        height: "78px",
        width: "138px",
        borderRadius: "8px",
        padding: "12px 24px",
        border: "1px solid #51ABB3",
        boxShadow: "0px 2px 8px 0px #00000014",
        cursor: "pointer",
        '&[data-active="true"]': {
            background: "#F0F9FA",
        },
    },
    "& .dialog-body .active-custom-btn": {
        background: "#F0F9FA",
    },
    "& .dialog-body .custom-btn span": {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    "& .dialog-body .custom-btn span p": {
        fontSize: "12px",
        color: "#64748B",
        textTransform: "capitalize",
        fontWeight: 700,
        fontFamily: "Cairo"
    },
    "& .dialog-body .check-box": {
        minHeight: "64px",
        padding: "0 24px",
        borderBottom: "1px solid #E2E8F0",
        borderRadius: "16px",
        width: "568px",
        boxSizing: "border-box",
        alignItems: "center"
    },
    "& .dialog-body .isChecked": {
        background: "#F0F9FA"
    },
    "& .dialog-body .check-box .MuiIconButton-colorSecondary:hover": {
        color: "#51ABB3"
    },
    "& .dialog-body .check-box .MuiIconButton-colorSecondary": {
        color: "#51ABB3"
    },
    "& .dialog-body .check-box .box": {
        display: "flex",
        padding: "10px 24px",
        gap: "10px",
        minWidth: "350px",
    },
    "& .dialog-body .check-box .box p": {
        fontSize: "14px",
        fontWeight: 700,
        color: "#0F172A",
        fontFamily: "Cairo"
    },
    "& .bulk-upload-btn": {
        cursor: "pointer",
        background: "#fff",
        color: "#0F172A",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px"
    },
    "& .bulk-upload-btn span": {
        gap: "6px",
    },
    "& .upload-btn": {
        background: "rgba(81, 171, 179, 0.1)",
        borderRadius: "8px",
        padding: "8px",
        height: "265px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px dotted #51ABB3"
    },
    "& .upload-btn h4": {
        color: "rgba(81, 171, 179, 1)",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "center",
    },
    "& .upload-btn label": {
        color: "#51ABB3",
        textTransform: "capitalize",
        background: "#E5F6FF",
        border: "1px solid #51ABB3",
        boxShadow: "none",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        textAlign: "center",
        borderRadius: "8px"
    },
    "& .dialog-footer button": {
        height: "44px",
        width: "105px",
        padding: "16px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    "& .dialog-footer button:nth-child(1)": {
        background: "#E5F6FF",
        color: "#51ABB3"
    },
    "& .dialog-header h4": {
        fontFamily: "Cairo",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "left",
    },
    "& .dialog-header button": {
        width: "14px",
        height: "14px",
        background: "#fff",
        border: "none",
        color: "#334155"
    },
})
